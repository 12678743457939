import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import AddForm from "./components/addForm.js";

const AddModelPage = ({ isParentModel, setSelectedModel }) => {
  const { companyId, companyName, parentId } = useParams();
  const [companyModels, setCompanyModels] = useState([]);
  const [parentModel, setParentModel] = useState();
  const [modelDetails, setModelDetails] = useState({
    modelId: '',
    model: '',
    modelStatus: '1',
    modelCatId: companyId,
    modelParentId: '0',
    modelColor: '#FFFFFF',
    modelType: '',
    startPrice: '',
    leasingPrice: '',
    environmentalBonus: '0.00',
    deliveryPriod: '',
    batteryCapacity: '',
    rangeWLTP: '',
    kmh: '',
    maxSpeed: '',
    averageChargingTime: '',
    youtubeLink: '',
    interior: '',
    exterior: '',
    productLink: '',
    modelWarranty: [],
    modelColors: [],
    chargingInfo: [],
    productImages: [],
  });

  async function fetchModelNames(brandModel) {
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/getallmodelnamebycategory/${brandModel}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
            Cookie:
              "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          },
        }
      );

      const dataResponse = await response.json();
      if (dataResponse && dataResponse.status) {
        setCompanyModels(dataResponse.data);
        if (parentId) {
          const requiredModel = dataResponse.data.find(
            (model) => model.id.toString() === parentId.toString()
          );
          setParentModel(requiredModel);
          setModelDetails((prev) => {
            return { ...prev, modelParentId: requiredModel.id };
          });
        }
      } else setCompanyModels([]);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchNextModelId() {
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/getNextModelId?categoryId=${companyId}&parentId=${
          isParentModel ? "0" : parentId
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
            Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
          },
        }
      );

      const dataResponse = await response.json();
      if (dataResponse && dataResponse.status) {
        setModelDetails((prev) => {
          return { ...prev, modelId: dataResponse.modelId };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (companyId) {
      fetchModelNames(companyId);
      fetchNextModelId();
    }
  }, [companyId]);

  return (
    <div>
      <h2 className="uppercase font-semibold text-xl leading-5 py-2 mx-8">
        {companyName}
      </h2>
      {modelDetails && (
        <AddForm
          modelDetails={modelDetails}
          isParentModel={isParentModel}
          modelNames={companyModels}
          parentModel={parentModel}
          setSelectedModel={setSelectedModel}
        />
      )}
    </div>
  );
};

export default AddModelPage;
