import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/index";
import Sidebar from "../../components/sidebar/index";

import routes from "../../routes";
import AdminFooter from "../../components/footer/AdminFooter";
import EditModel from "../../views/admin/editModel";
import AddModelPage from "../../views/admin/addModel";
import EditProfile from "../../views/admin/edit-profile";
import CompanyDahsboard from "../../views/admin/dashboard/CompanyDahsboard";
import EditUsedModel from "../../views/admin/editUsedModel";
import AddUsedModel from "../../views/admin/addUsedModel";
import AddNewSlider from "../../views/admin/sliders/addNew";
import AddNewVendorSlider from "../../views/admin/edit-profile/addNew";
import AddNewVendorMobileSlider from "../../views/admin/edit-profile/addNewMobile";
import AddNewMobileSlider from "../../views/admin/sliders/addNew/mobileIndex";
import Reviews from "../../views/admin/reviews";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [selectedModel, setSelectedModel] = React.useState(null);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes
      .filter((route) => route.name !== "Products")
      .map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />
          );
        } else {
          return null;
        }
      });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main className={`h-full flex-none transition-all xl:ml-[240px]`}>
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[89vh] p-2 md:pr-2 flex flex-col">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
                <Route
                  path="/products/:companyId/:companyName"
                  element={
                    <EditModel
                      newSelectedModel={selectedModel}
                    />
                  }
                />
                <Route
                  path="/products/:companyId/:companyName/new-model"
                  element={
                    <AddModelPage
                      isParentModel={true}
                      setSelectedModel={setSelectedModel}
                    />
                  }
                />
                <Route
                  path="/products/:companyId/:companyName/:parentId/new-submodel"
                  element={<AddModelPage />}
                />
                <Route
                  path="/usedproducts/:categoryId/:companyName"
                  element={<EditUsedModel />}
                />
                <Route
                  path="/usedproducts/:categoryId/:companyName/new-used-model"
                  element={<AddUsedModel />}
                />
                <Route
                  path="/:companyId/:companyName/edit-profile"
                  element={<EditProfile />}
                />
                <Route
                  path="/dashboard/:companyId/:companyName"
                  element={<CompanyDahsboard />}
                />
                <Route path="/sliders/new-slider" element={<AddNewSlider />} />
                <Route
                  path="/mobile-sliders/new-mobile-slider"
                  element={<AddNewMobileSlider />}
                />
                <Route
                  path="/:companyId/:companyName/edit-profile/new-slider"
                  element={<AddNewVendorSlider />}
                />
                <Route
                  path="/:companyId/:companyName/edit-profile/new-mobile-slider"
                  element={<AddNewVendorMobileSlider />}
                />
                <Route
                  path="/:companyId/:companyName/reviews"
                  element={<Reviews />}
                />
              </Routes>
              <AdminFooter />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
