import { Formik, FieldArray } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/AuthContext';
import { Copy, PlusCircle, Trash2 } from 'lucide-react';
import ProductLink from './productLink';
import SingleModelWarranty from './singleModelWarranty';
import SingleSingleModelChargingInfo from './singleSingleModelChargingInfo';
import { ModelSelectors } from '../../editModel/components/combobox';
import { useToast } from "../../../../components/toast/use-toast.tsx";
import ModelType from '../../editModel/components/modelType';
import { bodyTypesData } from '../../../../constant/data';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SingleModelColor from './singleModelColor';

const EditForm = ({ modelDetails, modelNames }) => {
  const { user } = useContext(AuthContext);
  const [responseErrors, setResponseErrors] = useState(null);
  const [selectedParentModel, setSelectedParentModel] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const { toast } = useToast();

  const {
    modelId,
    model,
    modelStatus,
    modelCatId,
    modelParentId,
    modelColor,
    modelType,
    startPrice,
    leasingPrice,
    environmentalBonus,
    deliveryPriod,
    batteryCapacity,
    rangeWLTP,
    kmh,
    maxSpeed,
    averageChargingTime,
    youtubeLink,
    interior,
    exterior,
    productLink,
    modelWarranty,
    dealImage,
    dealLink,
    metaTitle,
    metaDescription,
    whyItsPopular,
    top10Category
  } = modelDetails?.productDetail[0];

  const [selectedModelType, setSelectedModelType] = useState(modelType ? bodyTypesData.find((modelTyoe) => modelTyoe.name === modelType) : null);
  const parsedTop10Category = (top10Category && JSON.parse(top10Category)) || [];

  let {
    chargingInfo,
    productImages,
    modelColors
  } = modelDetails;

  productImages = productImages.map(im => im.productImages);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
  
      formData.append('modelId', values.modelId || '');
      formData.append('model', values.model || '');
      formData.append('modelStatus', values.modelStatus || '');
      formData.append('modelCatId', values.modelCatId || '');
      formData.append('modelParentId', values.modelParentId || '');
      formData.append('modelColor', values.modelColor || '');
      formData.append('modelType', values.modelType || '');
      formData.append('modelMasterWarrenty', values.modelMasterWarrenty || '');
      formData.append('startPrice', values.startPrice || 0.0);
      formData.append('leasingPrice', values.leasingPrice || 0.0);
      formData.append('environmentalBonus', values.environmentalBonus || 0.00);
      formData.append('deliveryPriod', values.deliveryPriod || '');
      formData.append('batteryCapacity', values.batteryCapacity || 0.0);
      formData.append('rangeWLTP', values.rangeWLTP || 0);
      formData.append('kmh', values.kmh || 0.0);
      formData.append('maxSpeed', values.maxSpeed || 0);
      formData.append('averageChargingTime', values.averageChargingTime || '');
      formData.append('youtubeLink', values.youtubeLink || '');
      formData.append('dealLink', values.dealLink || '');
      formData.append('interior', values.interior || '');
      formData.append('exterior', values.exterior || '');
      formData.append('productLink', values.productLink || '');
      formData.append('metaTitle', values.metaTitle || '');
      formData.append('metaDescription', values.metaDescription || '');

      let top10Category = [];

      if (values.isTop10BestSelling) {
        top10Category.push('Top 10 Best-Selling EV');
      }
      
      if (values.isTop10BestReichweite) {
        top10Category.push('Top 10 Best-Reichweite EV');
      }

      if (values.isTop10BestFamily) {
        top10Category.push('Top 10 Best-Family EV');
      }

      if (values.isTop10Upcoming) {
        top10Category.push('Top 10 Upcoming EV');
      }

      if (values.isTop10Newest) {
        top10Category.push('Top 10 Newest EV');
      }

      if (values.isTop10Chinese) {
        top10Category.push('Top 10 Chinese EV');
      }

      if (values.isTop10Technology) {
        top10Category.push('Top 10 Technology EV');
      }

      if (values.isTop10SUV) {
        top10Category.push('Top 10 SUV EV');
      }

      if (values.isTop10Kombi) {
        top10Category.push('Top 10 Kombi EV');
      }
      
      if (values.isTop10Luxury) {
        top10Category.push('Top 10 Luxury EV');
      }

      formData.append('top10Category', JSON.stringify(top10Category) || '');

      formData.append('whyItsPopular', values.whyItsPopular || '');
  
      // Handle file input for dealImage
      if (values.dealImage) {
        formData.append("dealImage", values.dealImage);
      }

      // Append arrays individually
      values.modelWarranty && values.modelWarranty.forEach((warranty, index) => {
        formData.append(`modelWarranty[${index}][type]`, warranty.type);
        formData.append(`modelWarranty[${index}][value]`, warranty.value);
        formData.append(`modelWarranty[${index}][description]`, warranty.description);
      });
  
      values.modelColors && values.modelColors.forEach((color, index) => {
        formData.append(`modelColors[${index}][colorCode]`, color.colorCode);
        formData.append(`modelColors[${index}][colorName]`, color.colorName);
      });
  
      values.productImages && values.productImages.forEach((image, index) => {
        formData.append(`productImages[${index}]`, image);
      });
  
      values.modelChargingInfo && values.modelChargingInfo.forEach((chargingInfo, index) => {
        formData.append(`modelChargingInfo[${index}][batteryCapacity]`, chargingInfo.batteryCapacity);
        formData.append(`modelChargingInfo[${index}][onBoardCharger]`, chargingInfo.onBoardCharger);
        formData.append(`modelChargingInfo[${index}][chargingPoint]`, chargingInfo.chargingPoint);
        formData.append(`modelChargingInfo[${index}][priceKWH]`, chargingInfo.priceKWH);
        formData.append(`modelChargingInfo[${index}][time]`, chargingInfo.time);
        formData.append(`modelChargingInfo[${index}][estimatedcost]`, chargingInfo.estimatedcost);
        formData.append(`modelChargingInfo[${index}][zeit]`, chargingInfo.zeit);
      });

      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/editExistingModel?token=${user?.token}`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );
  
      const dataResponse = await response.json();
      if (dataResponse.status) {
        setResponseErrors(null);
        toast({
          title: 'Model Edited',
          description: 'Model successfully edited',
        });
      } else {
        setResponseErrors(dataResponse.message);
        setTimeout(() => {
          setResponseErrors(null);
        }, 2000);
      }
      setSubmitting(false);
    } catch (error) {
      setResponseErrors('Unable to update values, try again later');
      setTimeout(() => {
        setResponseErrors(null);
      }, 5000);
      console.log(error);
    }
  };

  const handleDealImageUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      setFieldValue('dealImage', file);
    }
  };

  const removeDealImage = (setFieldValue) => {
    setFieldValue('dealImage', null);
  };

  const handleImageUpload = async (files, modelId, arrayHelpers) => {
    setIsImageUploading(true);
    const formData = new FormData();
    for (let file of files) {
      formData.append('images', file);
    }
    formData.append('modelId', modelId);

    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/addImage`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          token: user?.token
        },
      });

      const data = await response.json();
      if (response.ok) {
        for (let imageName of data.imageNames) {
          arrayHelpers.push(imageName);
        }
        setIsImageUploading(false);
        toast({
          title: 'Image Uploaded',
          description: 'Image uploaded successfully',
        });
      } else {
        setIsImageUploading(false);
        throw new Error(data.message || 'Image upload failed');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast({
        title: 'Error',
        description: 'Image upload failed',
        status: 'error',
      });
    }
  };

  const handleImageDelete = async (fileName, arrayHelpers, index) => {
    const body = {
      imageName: fileName
    };
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/deleteImage`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          token: user?.token,
          "Content-Type": "application/json"
        },
      });

      const data = await response.json();
      if (response.ok) {
        arrayHelpers.remove(index);
        toast({
          title: 'Image Deleted',
          description: 'Image Deleted successfully',
        });
      } else {
        throw new Error(data.message || 'Image Could not be deleted');
      }
    } catch (error) {
      console.error('Error Deleting image:', error);
      toast({
        title: 'Error',
        description: 'Image Could not be deleted',
        status: 'error',
      });
    }
  };

  const handleModelChange = (modelId, setFieldValue) => {
    const requiredModel = modelNames.find(model => model.id.toString() === modelId.toString());
    setFieldValue('modelParentId', requiredModel ? requiredModel?.id : '0');
    setSelectedParentModel(requiredModel);
  };

  const handleModelTypeChange = (modelType, setFieldValue) => {
    setFieldValue('modelType', modelType ? modelType?.name : '');
    setSelectedModelType(modelType);
  };

  const onDragEnd = (result, values, setFieldValue) => {
    if (!result.destination) return;
    const items = Array.from(values.productImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('productImages', items);
  };

  useEffect(() => {
    const requiredModel = modelNames.find(model => model.id.toString() === modelParentId.toString());
    setSelectedParentModel(requiredModel);
  }, [modelDetails]);

  return (
    <div>
      <Formik
        initialValues={{
          modelId,
          model,
          modelStatus,
          modelCatId,
          modelParentId,
          modelColor,
          modelType,
          modelMasterWarrenty: '',
          startPrice,
          leasingPrice,
          environmentalBonus,
          deliveryPriod,
          batteryCapacity,
          rangeWLTP,
          kmh,
          maxSpeed,
          averageChargingTime,
          youtubeLink,
          dealImage,
          dealLink,
          interior,
          exterior,
          productLink,
          modelWarranty,
          modelColors,
          modelChargingInfo: chargingInfo,
          productImages,
          newModelColor: '',
          newProductImage: '',
          newModelWarranty: {
            type: "",
            value: "",
            description: "",
          },
          newModelChargingInfo: {
            batteryCapacity: "",
            onBoardCharger: "",
            chargingPoint: "",
            priceKWH: "",
            time: "",
            estimatedcost: "",
            zeit: ""
          },
          newModelColor:{
            colorCode: "",
            colorName: ""
          },
          metaTitle: metaTitle,
          metaDescription: metaDescription,
          isTop10BestSelling: parsedTop10Category.includes('Top 10 Best-Selling EV') ? true : false,
          isTop10BestReichweite: parsedTop10Category.includes('Top 10 Best-Reichweite EV') ? true : false,
          isTop10BestFamily: parsedTop10Category.includes('Top 10 Best-Family EV') ? true : false,
          isTop10Upcoming: parsedTop10Category.includes('Top 10 Upcoming EV') ? true : false,
          isTop10Newest: parsedTop10Category.includes("Top 10 Newest EV") ? true : false,
          isTop10Chinese: parsedTop10Category.includes("Top 10 Chinese EV") ? true : false,
          isTop10Technology: parsedTop10Category.includes("Top 10 Technology EV") ? true : false,
          isTop10SUV: parsedTop10Category.includes("Top 10 SUV EV") ? true : false,
          isTop10Kombi: parsedTop10Category.includes("Top 10 Kombi EV") ? true : false,
          isTop10Luxury: parsedTop10Category.includes("Top 10 Luxury EV") ? true : false,
          whyItsPopular: whyItsPopular || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, { setSubmitting });
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className='flex flex-col lg:grid lg:grid-cols-2 gap-y-2 lg:gap-y-6 py-8'>
            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Model ID: </span>
              <input
                type="text"
                name="modelId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.modelId}
                disabled
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.modelId && touched.modelId && errors.modelId}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text
              left'>Model Name: </span>
              <input
                type="text"
                name="model"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.model}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.model && touched.model && errors.model}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Parent Model ID: </span>
              <ModelSelectors
                modelsList={modelNames}
                subModelsList={null}
                handleModelChange={(modelId) => handleModelChange(modelId, setFieldValue)}
                handleSubmodelChange={() => { }}
                selectedModel={selectedParentModel}
                selectedSubModel={null}
                className='px-0 text-right w-full lg:w-8/12'
                widthInPX="w-full"
                disabled={!selectedParentModel}
              />
              {errors.modelParentId && touched.modelParentId && errors.modelParentId}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Model Type: </span>
              <ModelType selectedModelType={selectedModelType} handleModelTypeChange={(model) => handleModelTypeChange(model, setFieldValue)} />
              {errors.modelType && touched.modelType && errors.modelType}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Start Price: </span>
              <input
                type="text"
                name="startPrice"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.startPrice > 0 ? values.startPrice : ""}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.startPrice && touched.startPrice && errors.startPrice}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Leasing Price: </span>
              <input
                type="text"
                name="leasingPrice"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.leasingPrice > 0 ? values.leasingPrice : ""}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.leasingPrice && touched.leasingPrice && errors.leasingPrice}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Delivery Period: </span>
              <input
                type="text"
                name="deliveryPriod"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deliveryPriod}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.deliveryPriod && touched.deliveryPriod && errors.deliveryPriod}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Battery Capacity: </span>
              <input
                type="text"
                name="batteryCapacity"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.batteryCapacity > 0 ? values.batteryCapacity : ''}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.batteryCapacity && touched.batteryCapacity && errors.batteryCapacity}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Range WLTP: </span>
              <input
                type="text"
                name="rangeWLTP"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.rangeWLTP > 0 ? values.rangeWLTP : ''}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.rangeWLTP && touched.rangeWLTP && errors.rangeWLTP}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>0–100 km/h: </span>
              <input
                type="text"
                name="kmh"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.kmh > 0 ? values.kmh : ''}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.kmh && touched.kmh && errors.kmh}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Max Speed: </span>
              <input
                type="text"
                name="maxSpeed"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.maxSpeed > 0 ? values.maxSpeed : ''}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.maxSpeed && touched.maxSpeed && errors.maxSpeed}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8 items-start'>
              <span className='py-2 lg:w-4/12 text-left'>Youtube Link: </span>
              <input
                type="text"
                name="youtubeLink"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.youtubeLink}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.youtubeLink && touched.youtubeLink && errors.youtubeLink}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8 items-start'>
              <span className='py-2 lg:w-4/12 text-left'>Deal City Image: </span>
              <div className='lg:w-8/12 py-2 px-2 flex items-center relative border-neutral-500 border rounded-sm'>
                <input
                  type="file"
                  name="dealImage"
                  accept="image/*"
                  onChange={(e) => handleDealImageUpload(e, setFieldValue)}
                  onBlur={handleBlur}
                  className='py-2 px-2 w-full'
                />
              </div>
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8 items-start'>
              <span className='py-2 lg:w-4/12 text-left'>Deal City Link: </span>
              <div className='lg:w-8/12 py-2 px-2 flex items-center relative border-neutral-500 border rounded-sm'>
                <input
                  type="text"
                  name="dealLink"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dealLink}
                  className='text-right focus:outline-0 w-full'
                >
                </input>
              </div>
              {errors.dealLink && touched.dealLink && errors.dealLink}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8 items-start'>
              <span className='py-2 lg:w-4/12 text-left'>Product Link: </span>
              <input
                type="text"
                name="productLink"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.productLink}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.productLink && touched.productLink && errors.productLink}
            </label>

            {/* New fields for metaTitle and metaDescription */}
            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Meta Title: </span>
              <textarea
                name="metaTitle"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.metaTitle}
                // maxLength="50"
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.metaTitle && touched.metaTitle && (
                <p className="text-red-600">{errors.metaTitle}</p>
              )}
            </label>

            <label className='flex flex-col lg:flex-row justify-between px-8'>
              <span className='py-2 lg:w-4/12 text-left'>Meta Description: </span>
              <textarea
                name="metaDescription"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.metaDescription}
                className='py-2 px-2 text-right w-full lg:w-8/12 border-neutral-500 border rounded-sm'
              />
              {errors.metaDescription && touched.metaDescription && (
                <p className="text-red-600">{errors.metaDescription}</p>
              )}
            </label>

            {/* Existing form fields continue... */}

            <label className='hidden lg:flex lg:w-full lg:flex-row justify-between px-8 items-start'>
            </label>

            <FieldArray
              name="modelColors"
              render={arrayHelpers => (
                <div className='px-8 border py-4 shadow-md mx-2 mr-8 md:mr-2'>
                  <label className='flex flex-col justify-between items-center mb-4'>
                    <span className='py-2 lg:w-4/12 text-left self-start'>Model Colors: </span>
                    <div className='relative flex items-center w-full mb-2'>
                      <input
                        type="text"
                        name="newModelColor.colorCode"
                        placeholder='Color | #87CEEB'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newModelColor.colorCode}
                        className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm'
                      />
                      {values.newModelColor && <div style={{backgroundColor: values.newModelColor.colorCode}} className={'absolute left-2 top-1/2 -translate-y-1/2 rounded-full w-8 h-8 border'} />}
                    </div>
                    <input
                      type="text"
                      name="newModelColor.colorName"
                      placeholder='Name | Sky Blue'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelColor.colorName}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-2'
                    />
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push(values.newModelColor);
                        setFieldValue('newModelColor',{
                          colorCode: "",
                          colorName: ""
                        })
                      }}
                      className='p-2 bg-black text-white rounded-lg'
                    >
                      Add Color
                    </button>
                  </label>
                  <div className='flex w-full justify-start gap-4 flex-wrap rounded-sm p-2'>
                    {values.modelColors && values.modelColors.length > 0 ? (
                      values.modelColors.map((singleModelColor, index) => (
                        <div key={index} className='flex flex-col min-w-44 gap-2 p-2 px-3 border border-neutral-500 rounded-md max-w-full items-center'>
                          <SingleModelColor modelColor={singleModelColor} />
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Trash2 size={18}/>
                          </button>
                        </div>
                      ))
                    ) : (
                      null
                    )}
                  </div>
                </div>
              )}
            />

            <FieldArray
              name="productImages"
              render={arrayHelpers => (
                <div className='px-8 border py-4 shadow-md mt-4 mx-2 mr-8 md:mr-2'>
                  <label className='flex flex-col lg:flex-row justify-between'>
                    <span className='py-2 lg:w-4/12 text-left'>Image Upload: </span>
                    <div className='relative flex items-center w-full lg:w-8/12 mb-4'>
                      <input
                        type='file'
                        name="newProductImage"
                        multiple="multiple"
                        onKeyDown={(e) => {
                          e.preventDefault();
                          if (e.key === 'Enter') {
                            const files = e.currentTarget.files;
                            if (files && files.length) {
                              handleImageUpload(files, values.modelId, arrayHelpers);
                              e.currentTarget.value = '';
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        disabled={isImageUploading}
                        className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm'
                      />
                      {isImageUploading && (
                        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                          <svg className="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                          </svg>
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          const fileInput = document.querySelector('input[name="newProductImage"]');
                          const files = fileInput.files;
                          if (files && files.length) {
                            handleImageUpload(files, values.modelId, arrayHelpers);
                            setFieldValue('newProductImage', '');
                            fileInput.value = '';
                          }
                        }}
                        className='p-2'
                        disabled={isImageUploading}
                      >
                        <PlusCircle size={24} />
                      </button>
                    </div>
                  </label>
                  <DragDropContext onDragEnd={(result) => onDragEnd(result, values, setFieldValue)}>
                    <Droppable droppableId="productImages">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='flex w-full justify-start gap-2 flex-wrap rounded-sm lg:p-2'>
                          {values.productImages && values.productImages.length > 0 ? (
                            values.productImages.map((productImage, index) => (
                              <Draggable key={productImage} draggableId={productImage} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className='flex gap-2 p-2 px-3 border border-neutral-500 rounded-md max-w-full'
                                  >
                                    <p className='truncate'>{productImage}</p>
                                    <button
                                      type="button"
                                      onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText(productImage); }}
                                    >
                                      <Copy size={18} />
                                    </button>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleImageDelete(productImage, arrayHelpers, index);
                                      }}
                                    >
                                      <Trash2 size={18} />
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            null
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
            />

            <FieldArray
              name="modelWarranty"
              render={arrayHelpers => (
                <div className='px-8 border py-4 shadow-md mx-2 ml-8 md:ml-2'>
                  <label className='flex flex-col justify-between items-center mb-4'>
                    <span className='py-2 lg:w-4/12 text-left self-start'>Model Warranty: </span>
                    <input
                      type="text"
                      name="newModelWarranty.type"
                      placeholder='Type'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelWarranty.type}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-2'
                    />
                    <input
                      type="number"
                      name="newModelWarranty.value"
                      placeholder='Value'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelWarranty.value}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-2'
                    />
                    <input
                      type="text"
                      name="newModelWarranty.description"
                      placeholder='Description'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelWarranty.description}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-4'
                    />
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push(values.newModelWarranty);
                        setFieldValue('newModelWarranty', { type: '', description: '', value: '' });
                      }}
                      className='p-2 bg-black text-white rounded-lg'
                    >
                      Add Model Warranty
                    </button>
                  </label>
                  <div className='flex w-full justify-start gap-4 flex-wrap rounded-sm p-2'>
                    {values.modelWarranty && values.modelWarranty.length > 0 ? (
                      values.modelWarranty.map((singleModelWarranty, index) => (
                        <div key={index} className='flex flex-col min-w-44 gap-2 p-2 px-3 border border-neutral-500 rounded-md max-w-full items-center'>
                          <SingleModelWarranty modelWarranty={singleModelWarranty} />
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Trash2 size={18} />
                          </button>
                        </div>
                      ))
                    ) : (
                      null
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Best-Selling EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10BestSelling"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10BestSelling}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Best-Reichweite EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10BestReichweite"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10BestReichweite}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Best-Family EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10BestFamily"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10BestFamily}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Upcoming EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10Upcoming"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10Upcoming}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Newest EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10Newest"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10Newest}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Chinese EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10Chinese"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10Chinese}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Technology EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10Technology"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10Technology}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 SUV EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10SUV"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10SUV}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Kombi EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10Kombi"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10Kombi}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sm:col-span-2 flex items-center mt-2">
                        <label className="ml-2 mr-2">
                          Is this Top 10 Luxury EV
                        </label>
                        <input
                          className="rounded-lg w-[16px] h-[16px]"
                          type="checkbox"
                          name="isTop10Luxury"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isTop10Luxury}
                        />
                      </div>
                    </div>
                  </div>
                  {(values.isTop10BestReichweite ||
                    values.isTop10BestSelling || values.isTop10BestFamily || values.isTop10Upcoming || 
                    values.isTop10Newest || values.isTop10Chinese || values.isTop10Technology || values.isTop10SUV ||
                    values.isTop10Kombi || values.isTop10Luxury) && (
                    <div className="flex flex-col mt-4">
                      <label htmlFor="description" className="mb-1">
                        Why It’s Popular:
                      </label>
                      <textarea
                        id="whyItsPopular"
                        name="whyItsPopular"
                        rows="8"
                        cols="50"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full p-2 border border-gray-300 rounded-md text-base resize-vertical"
                        value={values.whyItsPopular}
                      />
                    </div>
                  )}
                </div>
              )}
            />

            <FieldArray
              name="modelChargingInfo"
              render={arrayHelpers => (
                <div className='px-8 border py-4 shadow-md mx-2 mr-8 md:mr-2'>
                  <label className='flex flex-col justify-between items-center mb-4'>
                    <span className='py-2 lg:w-4/12 text-left self-start'>Charging Information: </span>
                    <input
                      type="number"
                      name="newModelChargingInfo.batteryCapacity"
                      placeholder='Battery Capacity'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelChargingInfo.batteryCapacity}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-2'
                    />
                    <input
                      type="number"
                      name="newModelChargingInfo.onBoardCharger"
                      placeholder='On Board Charger'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelChargingInfo.onBoardCharger}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-2'
                    />
                    <input
                      type="number"
                      name="newModelChargingInfo.chargingPoint"
                      placeholder='Charging Point'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelChargingInfo.chargingPoint}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-2'
                    />
                    <input
                      type="number"
                      name="newModelChargingInfo.priceKWH"
                      placeholder='Price KWH'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelChargingInfo.priceKWH}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-2'
                    />
                    <input
                      type="text"
                      name="newModelChargingInfo.zeit"
                      placeholder='Zeit'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelChargingInfo.zeit}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-4'
                    />
                    <input
                      type="text"
                      name="newModelChargingInfo.time"
                      placeholder='Time (11h45)'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelChargingInfo.time}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-4'
                    />
                    <input
                      type="text"
                      name="newModelChargingInfo.estimatedcost"
                      placeholder='Estimated Cost'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newModelChargingInfo.estimatedcost}
                      className='py-2 px-2 text-right w-full border-neutral-500 border rounded-sm mb-4'
                    />
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push(values.newModelChargingInfo);
                        setFieldValue('newModelChargingInfo', {
                          batteryCapacity: "",
                          onBoardCharger: "",
                          chargingPoint: "",
                          priceKWH: "",
                          time: "",
                          estimatedcost: "",
                          zeit: ""
                        });
                      }}
                      className='p-2 bg-black text-white rounded-lg'
                    >
                      Add Charging Information
                    </button>
                  </label>
                  <div className='flex w-full justify-start gap-4 flex-wrap rounded-sm p-2'>
                    {values.modelChargingInfo && values.modelChargingInfo.length > 0 ? (
                      values.modelChargingInfo.map((singleModelChargingInfo, index) => (
                        <div key={index} className='flex flex-col min-w-44 gap-2 p-2 px-3 border border-neutral-500 rounded-md max-w-full items-center'>
                          <SingleSingleModelChargingInfo modelChargingInfo={singleModelChargingInfo} />
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Trash2 size={18} />
                          </button>
                        </div>
                      ))
                    ) : (
                      null
                    )}
                  </div>
                </div>
              )}
            />

            <div className='col-span-2 flex justify-center items-center flex-col'>
              <p className='mb-2 text-red-600 text-lg font-medium'>{responseErrors}</p>
              <div>
                <button type="submit" disabled={isSubmitting} className='border px-4 py-1 rounded-lg bg-black text-white'>
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default EditForm;
